<template>
    <login-register-box>
        <template v-slot:formBox>
            <!-- 标题 -->
            <div class="register-title">
                <div class="register-title-en">SIGN-UP</div>
                <div class="register-title-cn">注册</div>
            </div>
            <!-- 表单部分 -->
            <div class="register-form-box">
                <el-form
                    label-position="top"
                    :model="form"
                    :rules="rules"
                    ref="registerForm">
                    <!-- 昵称 -->
                    <el-form-item
                        label="昵称 / Nickname"
                        prop="name">
                        <el-input
                            v-model="form.name"
                            placeholder="请输入昵称" >
                        </el-input>
                    </el-form-item>
                    <!-- 手机号码 -->
                    <el-form-item
                        label="手机号码 / Phone Number"
                        prop="phone">
                        <el-input
                            v-model="form.phone"
                            placeholder="请输入手机号码">
                        </el-input>
                    </el-form-item>
                    <!-- 短信验证 -->
                    <el-form-item
                        label="短信验证 / Message Verification"
                        prop="msgCode">
                        <el-input
                            v-model="form.msgCode"
                            placeholder="短信验证"
                            class="register-form-msg-code">
                            <!-- 获取短信按钮 -->
                            <el-button 
                                slot="suffix" 
                                type="info" 
                                size="mini"
                                @click="handleSendCode"
                                :disabled="msg_btn>=0"
                                plain>
                                <span v-if="msg_btn<0">获取验证码</span>
                                <span v-else>{{msg_btn+"s后重新获取"}}</span>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <!-- 验证码 -->
                    <el-form-item
                        label="验证码 / Verification"
                        prop="code"
                        class="register-form-identify">
                        <el-input
                            v-model="form.code"
                            placeholder="请输入验证码">
                            <!-- 验证码 -->
                            <a href="javascript:;" 
                                @click="changeCode" 
                                slot="suffix"
                                class="register-form-identify-code">
                                <s-identify :identifyCode="identifyCode"></s-identify>
                            </a>
                        </el-input>
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item
                        label="密码 / Password"
                        prop="pwd">
                        <el-input
                            v-model="form.pwd"
                            type="password"
                            placeholder="请输入密码">
                        </el-input>
                    </el-form-item>
                    <!-- 确认密码 -->
                    <el-form-item
                        label="确认密码 / Confirm Password"
                        prop="cpwd">
                        <el-input
                            v-model="form.cpwd"
                            type="password"
                            placeholder="请再次输入密码">
                        </el-input>
                    </el-form-item>
                    <!-- 角色选择 -->
                    <el-form-item
                        label="角色选择 / Role selection"
                        prop="role">
                        <el-row :gutter="15">
                            <el-col :span="8">
                                <a href="javascript:;"
                                    :class="['register-form-role',
                                    {
                                        'role-select': form.role == 21
                                    }]"
                                    @click="chooseRole(21)">
                                    摄影师
                                </a>
                            </el-col>
                            <el-col :span="8">
                                <a href="javascript:;"
                                    :class="['register-form-role',
                                    {
                                        'role-select': form.role == 22
                                    }]"
                                    @click="chooseRole(22)">
                                    化妆师
                                </a>
                            </el-col>
                            <el-col :span="8">
                                <a href="javascript:;"
                                    :class="['register-form-role',
                                    {
                                        'role-select': form.role == 1
                                    }]"
                                    @click="chooseRole(1)">
                                    个人用户
                                </a>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <!-- 协议 -->
                    <el-form-item
                        label=""
                        prop="checked">
                        <el-checkbox v-model="form.checked">
                            我已阅读并同意
                            <!-- <el-button type="text">《吉享空间协议》</el-button> -->
                            <a href="http://www.ijustsharing.com/public/user_agreement.html" 
                                target="view_frame"
                                v-if="form.role == 1">
                                《吉享空间用户者协议》
                            </a>
                            <a href="http://www.ijustsharing.com/public/business_agreement.html" 
                                target="view_frame"
                                v-else>
                                《吉享空间服务者协议》
                            </a>
                        </el-checkbox>
                    </el-form-item>
                    <!-- 提交按钮 -->
                    <el-form-item>
                        <el-button
                            type="warning"
                            class="register-form-submit"
                            @click="handleRegister">
                            注册
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </template>
    </login-register-box>
</template>

<script>
import SIdentify from '@/components/identify'
import { mapState, mapActions } from "vuex";

export default {
    data(){
        // 身份证验证
        let validataIdCode = (rule, value, callback) => {
            let reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            if(value === ""){
                callback(new Error('请输入身份证'));
            }else if(!reg.test(value)){
                callback(new Error('身份证不正确，请重新输入'));
            }else{
                callback();
            }
        };
        // 手机号验证
        let validataPhone = (rule, value, callback) => {
            let reg = /^1[3|5|7|8][0-9]\d{4,8}$/;
            if(value === ""){
                callback(new Error('请输入手机号'));
            }else if(!reg.test(value)){
                callback(new Error('手机号不正确，请重新输入'));
            }else{
                callback();
            }
        };
        // 密码验证
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
            if (this.form.cpwd !== '') {
                this.$refs.registerForm.validateField('cpwd');
            }
                callback();
            }
        };
        // 密码确认
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.pwd) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        let validataChecked = (rule, value, callback) => {
            if(!value){
                callback(new Error('您未同意协议'));
            }else{
                callback();
            }
        };
        return {
            form: {
                name: "", // 昵称
                phone:"", // 手机号码
                code:"", // 验证码
                msgCode: "", // 短信验证码
                pwd:"", // 密码
                cpwd: "", // 确认密码
                checked: false,  // 协议
                role: 0, // 角色
            },
            rules: {
                name: [
                    { required: true, message: '请输入昵称', trigger: 'blur' },
                ],
                phone: [
                    { required: true, validator: validataPhone, trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
                msgCode: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                ],
                pwd: [
                    { required: true, validator: validatePass, trigger: 'blur' },
                ],
                cpwd: [
                    { required: true, validator: validatePass2, trigger: 'blur' },
                ],
                checked: [
                    { required: true, validator: validataChecked, trigger: 'blur' },
                ],
                role: [
                    { required: true, message: '请选择角色', trigger: 'change' },
                ],
            },
            // 验证码组
            identifyCodes: "1234567890abcdefghijklmnopqrstuvwxyz",
            // 验证码
            identifyCode: "",

            // 短信验证码按钮文字
            msg_btn: -1,
        }
    },
    components: {
        LoginRegisterBox: () => import("@/components/LoginRegisterBox"),
        SIdentify
    },
    methods:{
        ...mapActions([
            "sendCode",
            "postRegister"
        ]),
        chooseRole(a){ // 切换角色
            this.form.role = a;
        },
        randomNum(min, max) { // 验证码函数
            return Math.floor(Math.random() * (max - min) + min);
        },
        refreshCode() { // 验证码函数
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        makeCode(o, l) { // 验证码函数
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[
                this.randomNum(0, this.identifyCodes.length)
                ];
            }
        },
        changeCode(){ // 更改验证码
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        countTime(val){ // 倒计时函数
            val -= 1;
            this.msg_btn = val;
            if(val >= 0){
                setTimeout(()=>this.countTime(val), 1000);
            }
        },
        handleSendCode(){ // 发送短信验证码
            if(!!this.form.phone){
                this.sendCode(this.form.phone).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res,
                        type: "success"
                    })
                    this.countTime(60);
                }).catch(() => {})
            }else{
                this.$message({
                    showClose: true,
                    message: "您还没有输入手机号，请输入手机号",
                    type: "warning"
                })
            }
        },
        handleRegister(){ // 注册按钮事件
            this.$refs.registerForm.validate((valid) => {
                if(!valid){
                    return ;
                }
                this.postRegister({
                    name: this.form.name,
                    type: this.form.role,
                    phone: this.form.phone,
                    password: this.form.pwd,
                    smscode: this.form.msgCode
                }).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type
                    })
                    if(res.type == "success"){
                        setTimeout(() => {
                            this.$router.push("/login")
                        },1000)
                    }
                }).catch(() => {})
            })
        },
    },
    mounted() {
        this.refreshCode()
    }
}
</script>

<style scoped>
/* 标题 */
.register-title>div{
    font-size: 30px;
    text-align: center;
}
.register-title-en{
    font-weight: bold;
}
/* 表单部分 */
.register-form-box{
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #EBEEF5;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/* 表单标题 */
.register-form-box>>>.el-form-item__label{
    margin: 0;
    padding: 0;
    color: #909399;
}
/* 短信验证输入框 */
.register-form-msg-code>>>.el-input__inner,
.register-form-identify>>>.el-input__inner{
    padding-right: 120px;
}
/* 验证码 */
.register-form-identify-code{
    display: block;
    height: 40px;
    padding-top: 5px;
}
/* 角色 */
.register-form-role{
    display: block;
    text-align: center;
    border: 1px solid #8d9afc;
    border-radius: 20px;
    color: #8d9afc;
}
.role-select{
    background-color: #8d9afc;
    color: #fff;
}
/* 注册按钮 */
.register-form-submit{
    width: 100%;
}
</style>